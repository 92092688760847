body {
  --primary-color: #03d8a6;
  --parallax-overflow-color: rgba(97,219,190,0.85);
  --control-focus-shadow-color: rgba(97,219,190,0.5);
  --text-color-on-parallax-image: #ffffff;
  --shadow-color: rgba(100,100,100,0.5);
  --header-shadow-color: #b5b5b5;
  --border-color: #dee2e6;
  --background-color: #ffffff;
  --secondary-text-color: #8B8787;
  --disabled-button-text-color: #474c51;
  --disabled-button-border-color: #5a6269;

  --logo-image-height: 40px;
  --download-link-image-height: 42px;
  --header-margin: calc(var(--logo-image-height) + 2 * 0.3125rem + 2 * 0.5rem); /* --bs-navbar-brand-padding-y: 0.3125rem, --bs-navbar-padding-y: 0.5rem; */

  scroll-margin-top: var(--header-margin);
}

.app-wrapper {
  margin: 0;
  font-weight: 400;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12pt;
  background-color: var(--background-color);
  margin-top: var(--header-margin);
}

body a {
  color: var(--primary-color);
  text-decoration: none;
}

body a:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

h2 {
  font-size: 26pt !important;
}

li span {
  display: block;
}

.primary-text {
  color: var(--primary-color);
}

.secondary-text {
  color: var(--secondary-text-color);
}

.primary-color-markers li::marker {
  color: var(--primary-color);
  font-size: 14pt;
}

.paralax-image-overflow {
  background-color: var(--parallax-overflow-color);
}

.paralax-image {
  background: url("./resources/backgroundImage.jpg");

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
}

.learn-more-popover.popover {
  border: 0;
  box-shadow: 0px 1px 4px var(--shadow-color);
}

.learn-more-popover li+li {
  margin-top: 1rem;
}

.learn-more-popover ul {
  margin-bottom: 0;
}

.home-section {
  padding: 5rem 0;
  color: var(--text-color-on-parallax-image);
  line-height: 1.5;
}

.home-section h2 {
  font-size: 20pt;
  font-weight: 500;
}

.howitworks-section, .pricing-section {
  padding: 2rem 0 4rem 0;
}

.howitworks-section h2 {
  color: var(--text-color-on-parallax-image);
}

.solutions-section {
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 3rem;
}

.solutions-section .material-symbols-outlined {
  color: var(--primary-color);
  font-size: 40pt;
}

.solutions-section h2 {
  color: var(--primary-color);
}

.solutions-section h4 {
  font-weight: 400;
  color: var(--primary-color);
}

section, .scrollable {
  scroll-margin-top: var(--header-margin);
}

.download-link-image {
  height: var(--download-link-image-height);
}

button.btn {
  --bs-btn-color: var(--primary-color);
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-hover-bg: var(--primary-color);
  --bs-btn-hover-border-color: var(--primary-color);
  --bs-btn-active-bg: var(--primary-color);
  --bs-btn-active-border-color: var(--primary-color);
  --bs-btn-disabled-color: var(--disabled-button-text-color);
  --bs-btn-disabled-border-color: var(--disabled-button-border-color);
}

.download-section h1 {
  color: var(--primary-color);
}

.pricing-section > h2 {
  color: var(--text-color-on-parallax-image);
}

.pricing-section .card {
  height: 12.5rem;
}

.full-size {
    height: 100%;
    width: 100%;
}

@media (min-width: 768px)
{
  .border-top-right-radius-md-0 {
    border-top-right-radius: 0px !important;
  }
}

/* Footer */
.footer-wrapper {
  font-size: 10pt;
  color: #616161;
}

.footer-wrapper h4 {
  font-size: 12pt;
  color: var(--primary-color);
}

.footer-wrapper h5 {
  font-size: 10pt;
  color: var(--primary-color);
}

.footer-wrapper svg {
  margin-right: .2rem;
}

/* Header */
.header-logo, .header-brand {
  height: var(--logo-image-height);
}

.header-container {
  box-shadow: 0 0 3px var(--header-shadow-color);
  background-color: var(--background-color);
  left: 0;
  top: 0;
  right: 0;
  padding: .5rem;
}

.header-container.fixed-top {
  z-index: 10000;
}

.navbar-collapse .navbar-nav .nav-link {
  color: #000000;
  text-decoration: none;
  padding-top: 4pt;
  padding-bottom: 4pt;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.navbar-collapse .navbar-nav .nav-link:hover {
  color: var(--primary-color);
}

button.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-nav { 
  align-items: start;
}

.form-control:focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 0.25rem var(--control-focus-shadow-color) !important;
}

.modal-header .btn-close:focus {
  box-shadow: none;
}

.modal-content {
  --bs-modal-border-width: 0;
}

.modal .modal-dialog {
  margin-top: var(--header-margin);
  margin-bottom: var(--header-margin);
  min-height: calc(100% - var(--header-margin) * 2);
}