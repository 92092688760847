.wrapper {
    background-color: var(--background-color);
    box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
    border-radius: 10px;
}

.wrapper :global(.nav.nav-tabs) {
    border: 0;
}

.wrapper :global(.nav-tabs .nav-item .nav-link) {
    color: var(--primary-color);
    border-radius: 0;
    background-color: var(--background-color);
    border: 0;
    border-right: 1px solid var(--border-color);
    transition: none;
    margin: 0;
    padding: 1rem;
    outline: none;
}

.wrapper :global(.nav-tabs .nav-item:first-child .nav-link) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.wrapper :global(.nav-tabs .nav-item:nth-last-child(2) .nav-link) {
    border-bottom-left-radius: 10px;
}

.wrapper :global(.nav-tabs .nav-item:last-child .nav-link) {
    border-right: 1px solid var(--border-color);
}

.wrapper :global(.nav-tabs .nav-item .nav-link.active) {
    margin-left: -10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid var(--border-color);
    border-right: 0;
    color: var(--primary-color);
    text-decoration: none;
}

.wrapper :global(.nav-tabs .nav-item .nav-link:hover), .wrapper :global(.nav-tabs .nav-item .nav-link:focus) {
    text-decoration: none;
    isolation: auto;
}

.contentImage, .smallContentImage {
    object-fit: cover;
}

.contentImage {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 100%;
}

.smallContentImage {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 150px;
}

.contentImageOverlay, .smallContentImageOverlay {
    position: absolute;
    top: 0;
    left: 0;
}

.contentImageOverlay {
    background: linear-gradient(270deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.56) 30%, rgba(255,255,255,0.67) 61%, #ffffff 100%);
}

.smallContentImageOverlay {
    background: linear-gradient(0deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.56) 30%, rgba(255,255,255,0.67) 61%, #ffffff 100%);
}

.noOverlayFullWidthImage {
    max-height: 250px;
    object-fit: contain;
}

.wrapper :global(.tab-pane) {
    height: 100%;
    padding: 1rem;
}

.wrapper :global(.tab-content) {
    scroll-margin-top: 70px;
}

.wrapper :global(.tab-filler) {
    flex: 1;
    border-right: 1px solid var(--border-color);
}